<template>
  <div>
    <h3 class="text-lg font-bold mt-2 mb-2">Account Details</h3>
    <div class="grid grid-cols-1 xl:grid-cols-4 border border-blue-200">
      <div class="col-span-1 p-4">
        <div class="opacity-75 text-sm font-medium mb-1">Available Credit</div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
          {{ creditCardAccountData("available_credit", 0) | currency }}
        </div>
      </div>
      <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="opacity-75 text-sm font-medium mb-1">
          Available Balance
        </div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
          {{ creditCardAccountData("available_balance", 0) | currency }}
        </div>
      </div>
      <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="opacity-75 text-sm font-medium mb-1">
          Credit Limit
        </div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
          {{ creditCardAccountData("credit_card_limit", 0) | currency }}
        </div>
      </div>
      <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="opacity-75 text-sm font-medium mb-1">
          Status
        </div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
          {{ creditCardStatus }}
        </div>
      </div>
    </div>

    <div
      class="grid grid-cols-1 xl:grid-cols-4 border border-blue-200 mb-10"
      style="margin-top: -1px"
    >
      <div class="col-span-1 p-4">
        <div class="opacity-75 text-sm font-medium mb-1">Billing Date</div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          <ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
          {{ staff.profile.billing_date }}
        </div>
      </div>
      <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="text-sm font-medium mb-1">
          Salary Date
        </div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          <ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
          {{ staff.profile.salary_day }}
        </div>
      </div>
      <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="text-sm font-medium mb-1">
          Due Date
        </div>
        <div
          class="text-gray-500 text-sm font-light flex flex-row items-center"
        >
          <ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
          <!-- {{ staff.profile.payment_date }} -->
          {{ dueDate }}
        </div>
      </div>
      <!-- <div
        class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
      >
        <div class="text-sm font-medium mb-1">
          Clear
        </div>
        <button
          type="button"
          class="btn btn-sm btn-blue align-middle"
          @click.prevent="openConfirmModal"
          v-if="statement.condition != 'paid'"
        >
          Clear
        </button>
      </div> -->
    </div>

    <template v-for="(statement, i) in lastTwoStatement">
      <div :key="i" class="mb-4">
        <div class="flex justify-between mb-4">
          <h3 class="text-lg font-bold mt-2">
            {{ new Date(statement.created_at).format("MM Y") }} Statement
          </h3>
          <div class="flex">
            <button
              type="button"
              class="btn btn-sm btn-blue align-middle"
              @click.prevent="openConfirmModal"
              v-if="statement.condition != 'paid'"
            >
              Pay
            </button>
            <button
              type="button"
              class="btn btn-sm btn-green align-middle mx-4"
              @click.prevent="openTransferModal"
              v-if="statement.condition != 'paid'"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-sm btn-orange align-middle"
              @click.prevent="openCommentModal(statement)"
            >
              comments
            </button>
          </div>
        </div>
        <div
          class="grid grid-cols-1 xl:grid-cols-5 border border-blue-200 mb-10"
        >
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Opening Balance
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
              {{ statement.opening_balance | currency }}
            </div>
          </div>
          <div
            class="col-span-1 p-4 border-t xl:border-t-0 xl:border-t-0 xl:border-l border-blue-200"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              Amount Spent
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
              {{ statement.agg_balance | currency }}
            </div>
          </div>
          <div
            class="col-span-1 p-4 border-t xl:border-t-0 xl:border-t-0 xl:border-l border-blue-200"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              Deferred Charge
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
              {{ statement.deferred_plan_amount | currency }}
            </div>
          </div>
          <div
            class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              Total
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
              {{ statement.total_outstanding | currency }}
            </div>
          </div>
          <div
            class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              Status
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
              {{ statement.condition }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <div>
      <h3 class="text-lg -mb-4 font-bold mb-5">Statements History</h3>
      <datatable :data="statements" :columns="columns" :limit="8" />
    </div>

    <modal5
      className="w-11/12 md:w-443px lg:w-443px pt-10 pb-5 rounded"
      ref="balanceClear"
    >
      <template v-if="track == 'clearBalance'">
        <h4
          class="text-xl xl:text-2xl font-bold md:-ml-4 xl:-ml-4 mb-16 text-left text-gray-700"
        >
          Clear Balance
        </h4>

        <div
          class="w-320px md:w-443px lg:w-443px xl:w-443px h-16 bg-ash-600 -mx-6 -mt-12 md:-mx-16 xl:-mx-16"
        >
          <div class="flex justify-between px-6 lg:px-12 pt-6">
            <p
              class="text-14 font-extrabold text-gray-700 leading-6 text-right"
            >
              Available Credit
            </p>
            <p class="text-14 font-extrabold text-gray-700 text-left">
              {{
                staff.personal_account
                  ? staff.personal_account.available_credit
                  : "N/A"
              }}
            </p>
          </div>
        </div>
        <div class="w-320px md:w-400px xl:w-443px md:-mx-10 lg:-mx-16">
          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Opening Balance
            </p>
            <p class="text-12 font-normal text-red-500 text-left">
              {{ currentStatementData("opening_balance") }}
            </p>
          </div>

          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Interest on Amount Spent
            </p>
            <p class="text-12 font-normal text-red-500 text-left">
              {{ currentStatementData("interest") }}
            </p>
          </div>

          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Delayed Payment Charge
            </p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ currentStatementData("fees_and_charges") }}
            </p>
          </div>

          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Deferred Plan Charge
            </p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ currentStatementData("deferred_plan_amount") }}
            </p>
          </div>

          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Processing Fee
            </p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ processingFee }}
            </p>
          </div>

          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Amount Spent
            </p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ currentStatementData("agg_balance") }}
            </p>
          </div>

          <div class="lg:px-12">
            <svg
              width="363"
              height="1"
              class="mt-6"
              viewBox="0 0 363 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                opacity="0.06"
                x1="-4.37114e-08"
                y1="0.5"
                x2="363"
                y2="0.499968"
                stroke="black"
                stroke-dasharray="5 5"
              />
            </svg>
          </div>

          <div class="flex justify-between lg:px-12 pt-4">
            <p
              class="text-12 font-semibold mt-1 text-gray-700 leading-6 text-right"
            >
              Total due
            </p>
            <p class="text-20 font-bold text-blue-500 text-left">
              {{ currentStatementData("total_outstanding") }}
            </p>
          </div>

          <div class="flex justify-center mt-12 mb-6">
            <div class="flex flex-row">
              <button
                class="w-full px-6 md:px-10 xl:px-10 mr-3 py-4 rounded font-normal btn border border-ash-500 bg-transparent"
                @click.prevent="viewStatement"
                :disabled="downloading"
              >
                <sm-loader v-if="downloading" />
                <template v-else>
                  <div class="text-indigo-200 inline-flex">
                    <svg
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 8V10.6667C13 11.0203 12.8595 11.3594 12.6095 11.6095C12.3594 11.8595 12.0203 12 11.6667 12H2.33333C1.97971 12 1.64057 11.8595 1.39052 11.6095C1.14048 11.3594 1 11.0203 1 10.6667V8M3.66667 4.66667L7 8M7 8L10.3333 4.66667M7 8V1"
                        stroke="#30469F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      /></svg
                    ><span class="ml-2">Statement</span>
                  </div>
                </template>
              </button>

              <button
                class="w-full btn btn-blue rounded bg-blue-500 py-1 md:py-4 xl:py-4 px-8 md:px-14 xl:px-14 font-normal text-white lg:ml-1"
                @click.prevent="changeTrack"
                :disabled="outstandingAmount <= 0"
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </template>

      <template v-if="track == 'repaymentCard'">
        <h4 class="text-xl xl:text-2xl font-bold mb-16 text-left text-gray-700">
          Payment Method
        </h4>

        <div class="w-full">
          <template v-if="getFormError(repyamentError)">
            <div class="alert alert-red-soft -mt-6 mb-10">
              <span class="alert-icon">!</span>
              <span class="text-xs font-normal">{{
                getFormError(repyamentError)
              }}</span>
            </div>
          </template>
          <div class="w-full flex items-center -mt-4 pt-1 pb-2">
            <div
              class="w-4/12 lg:w-5/12 bg-gray-800"
              style="height: 1px; opacity: 0.04;"
            ></div>
            <div
              class="w-4/12 lg:w-3/12 text-sm font-bold text-gray-800 text-center"
            >
              Pay With
            </div>
            <div
              class="w-4/12 lg:w-5/12 bg-gray-800"
              style="height: 1px; opacity: 0.04;"
            ></div>
          </div>

          <div
            class="w-full flex flex-col cursor-pointer"
            v-for="(card, i) in repaymentCards.data"
            :key="i"
            @click="setCardId(i)"
          >
            <div class="w-full flex items-center">
              <div class="w-2/12">
                <img
                  :src="svg.visa"
                  alt=""
                  v-if="repaymentCardType(card.card_type) == 'visa'"
                />
                <img
                  :src="svg.master"
                  alt=""
                  v-if="repaymentCardType(card.card_type) == 'master'"
                />
              </div>

              <div class="w-8/12 sm:w-4/12 flex mt-1">
                <div
                  class="w-full flex justify-start items-center font-extrabold text-gray-800 text-14 pb-3"
                >
                  . . . .
                  <span class="ml-2 mt-2 font-normal">{{ card.last4 }}</span>
                </div>
              </div>
              <div class="w-2/12 sm:w-6/12 flex justify-end">
                <p
                  class="rounded-full h-5 w-5 bg-blue-200"
                  v-if="cardId != i"
                ></p>
                <div class="flex justify-center items-center" v-else>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" rx="12" fill="#274FED" />
                    <path
                      d="M17 9L10.125 16L7 12.8182"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="w-full bg-gray-800 my-4"
              style="height: 1px; opacity: 0.04;"
            ></div>
          </div>

          <div
            class="w-full bg-gray-800 mt-4"
            style="height: 1px; opacity: 0.04;"
          ></div>

          <div class="flex justify-center mt-50 mb-4">
            <button
              type="button"
              class="button border border-ash-500 bg-transparent text-sm lg:text-14 font-semibold rounded-sm py-4"
              @click.prevent="changeTrack"
            >
              <span class="text-ash-800">Back</span>
            </button>
            <button
              :disabled="clearing"
              type="button"
              class="button ml-3 bg-blue-500 hover:bg-blue-600 text-sm lg:text-14 font-semibold py-4 rounded-sm text-white"
              @click.prevent="proceedToRepayment"
            >
              {{ clearing ? "Loading" : "Continue" }}
            </button>
          </div>
        </div>
      </template>
    </modal5>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="transferChargeModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">
        Confirm Transfer
      </h4>

      <div>
        <!-- <template v-if="chargeError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ chargeError }}</span>
          </div>
        </template> -->
        <template v-if="getFormError(repyamentError)">
          <div class="alert alert-red-soft -mt-6 mb-10">
            <span class="alert-icon">!</span>
            <span class="text-xs font-normal">{{
              getFormError(repyamentError)
            }}</span>
          </div>
        </template>
        <template v-if="currentStatement">
          <div class="mb-4">
            This statement will be closed and all transactions will be marked as
            paid if you confirm, be sure user has transfered outstanding amount
            before closing.
          </div>

          <p class="text-gray-500">Amount</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
            <div class="text-xl font-bold">
              ₦{{ outstandingAmount | currency }}
            </div>
          </div>
        </template>

        <div class="text-center">
          <button
            @click.prevent="transfer"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="outstandingAmount <= 0 || clearing"
          >
            <template v-if="clearing">Charging...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-3/5 xl:w-4/10 pt-10"
      ref="commentModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-8">
        {{ selectedStatementMonth }} Statement
      </h4>
      <div class="flex-grow mb-4">
        <form>
          <form-group
            type="textarea"
            name="comment"
            :form="statementComment"
            v-model="statementComment.data.comment.value"
          >
            Comments.
          </form-group>

          <button
            type="button"
            class="btn btn-sm btn-blue ml-auto"
            :disabled="statementComment.loading"
            @click.prevent="makeComment"
          >
            <span v-if="statementComment.loading">Saving...</span>
            <span v-else>Save</span>
          </button>
        </form>
      </div>
      <div>
        <template v-for="(comment, i) in currentStetementComments">
          <div :key="i" class="border border-gray-500 mb-4">
            <div class="border-b border-gray-500 px-4 py-1 text-sm font-thin">
              {{ comment.admin.name }} {{ comment.admin.last_name }} commented
              on {{ new Date(comment.created_at).format("dd M, Y") }}
            </div>
            <div class="py-4 px-4 font-normal">{{ comment.body }}</div>
          </div>
        </template>
      </div>
    </modal>

    <modal
      className="w-11/12 md:w-3/5 xl:w-443px rounded text-center pt-24"
      ref="repaymentSuccessModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-12 mb-10">
        Repayment was successful.
      </div>
    </modal>

    <modal
      className="w-11/12 md:w-3/5 xl:w-443px rounded text-center pt-24"
      ref="commentSuccessModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-12 mb-10">
        Comment saved successfully.
      </div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      statements: [],
      columns: [
        {
          name: "created_at",
          th: "Month",
          render: (transaction, created_at) =>
            this.$options.filters.dateFormat(created_at, "M Y"),
        },
        {
          name: "created_at",
          th: "Statement Date",
          render: (transaction, created_at) =>
            this.$options.filters.dateFormat(created_at, "D, dd M Y h:ia"),
        },
        {
          className: "w-1/10",
          name: "action",
          th: "Statement",
          render: (statement) => {
            return `
                  <div class="flex items-center">
                    <button
                      class="btn btn-blue btn-sm mr-5"
                      data-click="viewStatementTable(${statement.id})"
                    >
                      View
                    </button>
                  </div>
                `;
          },
        },
        {
          className: "w-1/10",
          name: "action",
          th: "Comments",
          render: (statement) => {
            return `
                  <div class="flex items-center">
                    <button
                      class="btn btn-blue btn-sm mr-5"
                      data-click="openCommentTable(${statement.id})"
                    >
                      View
                    </button>
                  </div>
                `;
          },
        },
      ],
      currentStatement: null,
      track: "clearBalance",
      downloading: false,
      clearing: false,
      repyamentError: {},
      repaymentCards: this.$options.resource([]),
      cardId: 0,
      chargeError: null,
      svg: {
        visa: require("@/assets/visa-dark.svg"),
        master: require("@/assets/mastercard.svg"),
        check: require("@/assets/blue-check.svg"),
      },
      statementComment: this.$options.basicForm(["comment"]),
      currentStetementComments: [],
      lastTwoStatement: [],
      selectedStatement: null,
    };
  },
  computed: {
    processingFee() {
      return this.formatAmount(
        this.currentStatement?.statement?.processing_fee || 0
      );
    },
    outstandingAmount() {
      return this.currentStatement?.statement?.total_outstanding || 0;
    },
    selectedStatementMonth() {
      return new Date(this.selectedStatement?.created_at).format("MM Y");
    },
    creditCardStatus() {
      const today = new Date().getDate();
      const status =
        this.currentStatement?.statement?.condition !== "paid" &&
        this.staff?.profile?.salary_day >= today;
      return status ? "Due" : "Good";
    },
    dueDate() {
      let date =  new Date()
      const paymentDate = this.staff?.profile?.payment_date || 7;
      date.setDate(this.staff?.profile?.salary_day);
      date.setDate(date.getDate() + paymentDate)
      return date.format('dd M')
    }
  },
  beforeMount() {
    this.getUserStatements();
    this.getCurrentStatement();
    this.getRepaymentCards(this.staff?.id);
    // this.getStatementComments(1);
    this.getLastTwoStatement();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    changeTrack() {
      this.track =
        this.track === "clearBalance" ? "repaymentCard" : "clearBalance";
    },
    currentStatementData(key) {
      const data = this.currentStatement?.statement?.[key];
      return this.formatAmount(data || 0);
    },
    openConfirmModal() {
      this.$refs.balanceClear.open();
    },
    openTransferModal() {
      this.$refs.transferChargeModal.open();
    },
    startDate() {
      const date = new Date();
      date.setDate(this.staff?.profile.salary_day);
      const month = date.getMonth() - 1;
      date.setMonth(month);
      return moment(date).format();
    },
    endDate() {
      const date = new Date();
      date.setDate(this.staff?.profile.salary_day);
      return moment(date).format();
    },
    removeError() {
      this.chargeError = "";
    },
    repaymentCardType(cardType) {
      return cardType.includes("visa")
        ? "visa"
        : cardType.includes("verve")
        ? "verve"
        : "master";
    },
    setCardId(index) {
      this.cardId = index;
    },
    openCommentModal(statement) {
      this.selectedStatement = statement;
      this.getStatementComments(statement.id);
      this.$refs.commentModal.open();
    },
    openCommentTable(statementId) {
      this.selectedStatement = this.statements.find(
        (statement) => statement.id == statementId
      );
      this.getStatementComments(statementId);
      this.$refs.commentModal.open();
    },
    viewStatementTable(statementId) {
      this.selectedStatement = this.statements.find(
        (statement) => statement.id == statementId
      );
      this.viewSelectedStatement(statementId);
    },
    getRepaymentCardDetails() {
      return this.repaymentCards.data[this.cardId];
    },
    creditCardAccountData(key, default_value = "N/A") {
      const data = this.staff?.personal_account?.[key];
      return data ? data : default_value;
    },
    async getRepaymentCards(userId) {
      this.repaymentCards.loading = true;
      await this.$get({
        url: `${this.$baseurl}/staff/card/repayment/${userId}`,
        headers: this.headers,
        success: (response) => {
          this.repaymentCards.data = response.data.card;
        },
        error: (error) => {
          this.pin.error = error;
        },
      });
      this.repaymentCards.loading = false;
    },
    async getUserStatements() {
      this.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/personal/cards/${this.staff.id}/statements`,
        headers: this.headers,
        success: (response) => {
          this.statements = response.data.statements;
        },
        error: () => {},
      });
      this.loading = false;
    },
    async viewStatement() {
      this.downloading = true;
      axios
        .get(
          `${this.$baseurl}/personal/statement/${this.staff.personal_account?.id}/download`,
          {
            // responseType: "blob",
            headers: this.headers,
          }
        )
        .then((res) => {
          window.open(res.data);
        })
        .catch(() => {});
      this.downloading = false;
    },
    async viewSelectedStatement() {
      this.downloading = true;
      axios
        .get(
          `${this.$baseurl}/admin/statement/${this.selectedStatement?.id}/download`,
          {
            // responseType: "blob",
            headers: this.headers,
          }
        )
        .then((res) => {
          window.open(res.data);
        })
        .catch(() => {});
      this.downloading = false;
    },
    async getCurrentStatement() {
      if (!this.staff.personal_account) {
        return;
      }
      // this.creditAccounts.loading = true;
      await this.$post({
        url: `${this.$baseurl}/admin/personal/cards/user-balance`,
        headers: this.headers,
        data: {
          account_id: this.staff.personal_account?.id,
        },
        success: (response) => {
          this.currentStatement = response.data.data;
        },
        error: () => {
          // this.chargeError = error.response.data.message;
        },
      });
      // this.creditAccounts.loading = false;
    },
    async transfer() {
      this.clearing = true;
      await this.$post({
        url: `${this.$baseurl}/admin/personal/cards/repayment/transfer`,
        headers: this.headers,
        data: {
          user_id: this.staff.id,
          account_id: this.staff?.personal_account?.id,
          amount: this.currentStatement?.statement?.total_outstanding,
          start_date: this.startDate(),
          end_date: this.endDate(),
          payment_method: "transfer",
        },
        success: () => {
          this.$refs.transferChargeModal.close();
          this.$refs.repaymentSuccessModal.open();
        },
        error: (error) => {
          this.repyamentError.error = error;
        },
      });
      this.clearing = false;
      await this.$root.loadSession();
    },
    async proceedToRepayment() {
      this.clearing = true;

      await this.$post({
        url: `${this.$baseurl}/repayment/personal`,
        data: {
          payment_method: "repayment_card",
          authorization_code: this.getRepaymentCardDetails()[
            "authorization_code"
          ],
          account_id: this.staff?.personal_account?.id,
          start_date: this.startDate(),
          end_date: this.endDate(),
          // personal_statement_id
        },
        headers: this.headers,
        success: () => {
          this.$refs.balanceClear.close();
          this.$refs.repaymentSuccessModal.open();
          this.changeTrack();
        },
        error: (error) => {
          this.repyamentError.error = error;
        },
      });
      this.clearing = false;
      await this.$root.loadSession();
    },
    async makeComment() {
      this.statementComment.loading = true;

      await this.$post({
        url: `${this.$baseurl}/admin/statement/comments`,
        data: {
          body: this.statementComment.data.comment.value,
          statementId: this.selectedStatement.id,
        },
        headers: this.headers,
        success: () => {
          this.statementComment = this.resetForm(this.statementComment)
          this.getStatementComments(this.selectedStatement.id);
          this.$refs.commentSuccessModal.open();
        },
        error: (error) => {
          this.repyamentError.error = error;
        },
      });
      this.statementComment.loading = false;
      await this.$root.loadSession();
    },
    async getStatementComments(statementId) {
      this.statementComment.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/statement/comments/${statementId}`,
        headers: this.headers,
        success: (response) => {
          this.currentStetementComments = response.data.statement.comments;
        },
        error: (error) => {
          this.repyamentError.error = error;
        },
      });
      this.statementComment.loading = false;
      await this.$root.loadSession();
    },
    async getLastTwoStatement() {
      this.statementComment.loading = true;

      await this.$get({
        url: `${this.$baseurl}/admin/statement/${this.staff?.id}`,
        headers: this.headers,
        success: (response) => {
          this.lastTwoStatement = response.data.data;
        },
        error: (error) => {
          this.repyamentError.error = error;
        },
      });
      this.statementComment.loading = false;
      await this.$root.loadSession();
    },
  },
};
</script>
